
.priority-top {
    z-index: 1;
}

.priority-0 {
    z-index: 0;
}

.div-block-27 img:hover {
    cursor: pointer;
}

.vacancies-list-replace {
    .search-button-4 {
        min-width: 207px;
    }
}

.filter-item:hover {
    background-color: #00b8e3;
    color: #ffffff;
    cursor: pointer;
}

.filter-item:hover .checkmark {
    border: 2px solid #ffffff;
}

/** checkboxes */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .checkbox-filter {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
}

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border: 2px solid #898C8E;
  border-radius: 2px;
}

.filter-item:hover .container-checkbox .checkmark {
  border: 2px solid #ffffff;
}

.container-checkbox:hover input ~ .checkmark {
  border: 2px solid #ffffff;
}

.container-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.filter-item:hover .container-checkbox input:checked ~ .checkmark:after {
  border: solid white;
  border-width: 0 3px 3px 0;
}

.container-checkbox .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #898C8E;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.vacancies-list-replace .mobile-panel {
  display: none;
}

@media screen and (min-width: 479px) {
    .w-dropdown {
        z-index: 5;
    }
}

@media screen and (max-width: 479px) {
  .vacancies-list-replace {
    .dropdown-toggle-4 {
      margin-right: 3px !important;
    }

    .w-dropdown-list {
      position: fixed;
      bottom: 0;
      right: 0;
      top: auto;
      left: auto;
      width: 100%;
      margin: 0 auto;
      height: 70%;
      max-height: 70%;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      .mobile-list {
        height: 100%;
        overflow-y: scroll;
      }
    }

    .mobile-panel {
      display: block;
      z-index: 3;
      text-align: center;
      width: 100%;
      position: fixed;
      background: white;
      padding: 10px;
      bottom: calc(70% - 4px);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
      border-radius: 5px 5px 0px 0px;
    }

    .tap-mobile-panel {
      width: 102px;
      height: 8px;
      margin: 0 auto 7px;
      background: #D9D9D9;
      border-radius: 10px;
    }
  }
}
