
.width-full{
    width: 100%;
}

.align-self-start {
    align-self: start;
}

.validation-error-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
  font-size: 16px;
  line-height: 24px;
  vertical-align: top;
  background: none;
  border-radius: 4px;
  color: rgba(234, 89, 107, 1);
  font-weight: 500;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.13);
}

.form-field-textarea {
    resize: vertical;
}

.error-button {
  position: absolute;
  right: 7px;
  top: 12px;
}

.input-wrapper {
  display: flex;
  justify-content: end;
  position: relative;
  flex-direction: column;
}

.form-label.required::after {
    content: ' *';
    color: #7A9FFF;
}

.form-label {
    margin-bottom: 0;
    padding-top: 0;
}

@media screen and (max-width: 479px) {
  .form-field {
    margin-bottom: 25px;
  }

  .form-label {
    margin-bottom: 4px;
  }

  .validation-error-text {
    margin: -25px 0 16px 0;
  }
}

@media screen and (max-width: 767px) {
  .error-button {
    top: 9px;
  }
}
