
.vacancies-search-form {
    border-bottom: none;
}

.vacancies-search-form-block {
    margin-bottom: 0;
}

.search-list {
    z-index: 1000;
    position: relative;
    border-radius: 10px;
    width: 100%;
    margin: 2px 0 0;
    border: 1px solid gray;
    color: white;
}

.search_list-wrapper {
    margin-bottom: 16px;
}

.search-list ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.search-list ul li:first-child, .search-list ul li:last-child {
    border-radius: 10px;
}

.search-list ul li {
    padding: 7px 0 7px 16px;
    font-size: 16px;
    line-height: 24px;

}

.search-list ul li:hover {
    background-color: #cacaca;
    cursor: pointer;

}

.text-error {
    text-align: center;
    margin: 5px 0;
    color: red;
}

.validation-error-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 12px 6px 8px;
    min-width: 180px;
    font-size: 14px;
    vertical-align: top;
    top: 60px;
    left: 0;
    position: absolute;
    background: #FFE1E1;
    border-radius: 4px;
    color: #E04444;
    box-shadow: 0 2px 2px -2px gray;

    img {
        padding: 0 5px 2px 0;
        position: relative;
    }
}

.validation-error-text:before {
    content: "";
    position: absolute;
    left: 1.4rem;
    top: -0.4rem;
    width: 0.9rem;
    height: 0.9rem;
    transform: rotate(45deg);
    background: #FFE1E1;
}

.input-wrapper {
    width: 100%;
    position: relative;
}

.search-input {
    margin: 0;
    padding: 0 48px;

    &:read-only {
        background: transparent;
    }
}

@media screen and (max-width: 992px) {
    .search_list-wrapper {
        margin-left: 2%;
        margin-right: 2%;
    }
    .search-list {
        width: 100%;
    }
}

@media screen and (max-width: 479px) {
    input, textarea, select {
        font-size: 16px;
    }
    .search-mobile-list {
        width: 100%;
        height: 100%;
        position: fixed;
        border-radius: unset;
        background-color: rgba(13, 22, 43, 1);
        color: white;
        top: 0;
        left: 0;
        margin: 0;
        overflow: hidden;
        z-index: 9999;
    }
    .search-mobile-list input {
        background-color: rgba(33, 43, 78, 1);
        position: relative;
        margin: 10px 12px;
        width: 95%;
        height: 52px;
        padding-left: 45px;
        padding-right: 45px;
        border-radius: 40px;
        color: white;
        z-index: 100;
    }
    *:disabled {
        background-color: white;
        color: linen;
        opacity: 1;
    }
    .search-mobile-list ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .search-mobile-list ul li {
        padding: 0 0 0 12px;
        font-size: 16px;
        line-height: 44px;
        position: relative;

    }
    .vacancy_link {
        position: absolute;
        top: 0;
        right: 30px;
    }
    .vacancy_link:before {
        position: absolute;
        content: url('/public/img/m_search_vacancy_link.svg');
        color: white;
    }
    .back_link {
        position: absolute;
        top: 0;
        left: 0;
        vertical-align: middle;
        display: inline-block;
        z-index: 1000;
    }
    .back_link:before {
        position: absolute;
        content: url('/public/img/keyboard-arrow-left.svg');
        background-size: cover;
        display: inline-block;
        top: 28px;
        left: 30px;
        vertical-align: middle;
    }
    .clean_link {
        position: absolute;
        top: 0;
        right: 0;
        vertical-align: middle;
        display: inline-block;
        z-index: 1000;
    }
    .clean_link:before {
        position: absolute;
        content: url('/public/img/close-icon.svg');
        background-size: cover;
        display: inline-block;
        top: 28px;
        right: 30px;
        vertical-align: middle;
    }
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        input:focus, textarea:focus, input:focus {
            font-size: 16px;
        }
    }
}
