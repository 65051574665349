
.vacancies-list-replace {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.section-container {
    width: 100%;
    max-width: 970px;
}

@media screen and (max-width: 992px) {
    .vacancies-section {
        padding-top: 20px;
    }
}
