
.field-container {
  display: grid;
  grid-template-columns: 1fr 360px;
  margin-bottom: 16px;
  align-items: center;
  gap: 16px;
}

@media screen and (max-width: 767px) {
  .field-container {
    grid-template-columns: 1fr 300px;
  }
}

@media screen and (max-width: 480px) {
  .field-container {
    grid-template-columns: 1fr;
    margin: 0;
    gap: 0;
  }
  
  .form-submit-button {
    margin-top: 8px;
  }
}

.validation-error-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
    font-size: 16px;
    line-height: 24px;
    vertical-align: top;
    background: none;
    border-radius: 4px;
    color: rgba(234, 89, 107, 1);
    font-weight: 500;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.13);
}


.required::after {
    content: ' *';
    color: #7A9FFF;
}

.form {
  &-section {
    display: flex;
  }

  &-checkbox {
    margin: 0;
  }

  &-checkbox-label {
    width: fit-content;
    margin-left: 12px;
  }

  &-submit-button {
    font-weight: 600;
    line-height: 18px;
  }

  &-select-option {
    display: block;
    padding: 12px 12px 12px 16px;
    color: rgba(236, 241, 255, 1);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  
  &-label {
    padding-top: 0;
  }

  &-error-message {
    margin-top: 16px;
    color: rgba(234, 89, 107, 1);
  }
}

.grid-17 {
  display: grid;
}

@media screen and (max-width: 767px) {
  .grid-17 {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    justify-content: center;
  }
}

.checkbox-agree {
  padding: 0;
  grid-column-gap: 0;
}

.input-wrapper {
    position: relative;
}

.error-button {
    position: absolute;
    right: 7px;
    top: 12px;
}

.w-full{
    width: 100%;
}

.in-block{
    display: inline-block;
}

.width-full{
    width: 100%;
    max-width: 372px;
}
