
.validation-error-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
    font-size: 16px;
    line-height: 24px;
    vertical-align: top;
    background: none;
    border-radius: 4px;
    color: rgba(234, 89, 107, 1);
    font-weight: 500;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.13);
}

.upload-file-text {
  text-align: center;
  color: #00b8e3;
  margin: 5px 0;
  display: flex;
  justify-content: center;
}

.upload-file-text span {
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close-icon {
    display: flex;
    align-items: center
}

.input__file {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.input__file-button-text {
  line-height: 1;
  margin-top: 1px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input__file-button {
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
}

.form-label.required::after {
    content: ' *';
    color: #7A9FFF;
}

.input__file-button:active {
  width: 100%;
}
.form-label {
    margin-bottom: 8px;
    padding-top: 12px;
}

.file-upload-button {
  width: 100%;
  max-width: 315px;
}

@media scren and (max-width: 767px) {
  .file-upload-button {
    width: 100%;
    max-width: 315px;
  }
}
