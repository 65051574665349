
.form {
    &-label {
        padding-top: 12px; 
    }

    &-error-message {
        margin-top: 16px;
        color: rgba(234, 89, 107, 1);
    }
}
.form-label {
    padding-top: 12px;
}
.field-container {
  display: grid;
  grid-template-columns: 200px 1fr;
  margin-bottom: 26px;
  position: relative;
  align-items: center;
}

.z-index-999 {
    z-index: 999;
}

@media screen and (max-width: 568px) {
    .field-container {
        grid-template-columns: 165px 1fr;
    }
}

@media screen and (max-width: 767px) {
  .field-container {
    margin-bottom: 16px;

  }

  .select-block {
    max-width: 372px;
  }

  .form {
    &-submit-button {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 479px) {
    .w-checkbox {
        padding-bottom: 0;
    }
    .field-container {
        margin: 0;
        grid-template-columns: 1fr;
        margin-bottom: 0;
    }

    .validation-error-text {
        margin: -25px 0 16px 0;
    }

    .form-label {
        margin-bottom: 8px;
        padding-top: 0;
    }
    .form-field {
        margin-bottom: 0;
    }
    .form-submit-button {
        margin-top: 0;
    }
}

.validation-error-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
  font-size: 16px;
  line-height: 24px;
  vertical-align: top;
  background: none;
  border-radius: 4px;
  color: rgba(234, 89, 107, 1);
  font-weight: 500;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.13);
}

.required::after {
    content: ' *';
    color: #7A9FFF;
  }

.form {
  &-section {
    display: flex;
  }

  &-checkbox {
    margin: 0;
  }

  &-checkbox-label {
    width: fit-content;
    margin-left: 8px;
  }

  &-submit-button {
    margin-top: 16px;
    font-weight: 600;
    line-height: 18px;;
  }

  &-select-option {
    display: block;
    padding: 12px 12px 12px 16px;
    color: rgba(236, 241, 255, 1);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}

.input-wrapper {
  position: relative;
}
.error-button {
  position: absolute;
  right: 7px;
  top: 12px;
}

.checkbox-agree {
  padding: 0;
  grid-column-gap: 0;
}

.w-full{
  width: 100%;
}

.in-block{
  display: inline-block;
}

.width-full{
  width: 100%;
  max-width: 372px;
}
