
.hot-tab {
    background-color: transparent;
}
.card-title {
    text-transform: none;
}
.job-wrapper {
    text-decoration: none;
} 
