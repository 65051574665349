
.dropdown-link-selected {
  background-color: rgba(0, 4, 16, 0.34);
}

.popup-dropdown-wrapper {
  overflow-y: scroll;
}

.form-field-select {
    margin-left: 0;
    margin-right: 0;
}

.text-block-66 {
    overflow: hidden;
}

.dropdown-link-6 {
    white-space: normal;
}

.input-wrapper {
  display: flex;
  position: relative;
}

@media screen and (max-width: 479px) {
  .input-wrapper {
    margin-bottom: 0;
  }

  .form-field-select {
    margin-bottom: 25px;
  }
}
