
.close-icon {
  cursor: pointer;
}

.text-error {
  text-align: center;
  margin: 5px 0;
  color: red;
}

.upload-file-text {
  text-align: center;
  color: #00b8e3;
  margin: 5px 0;
  display: flex;
  justify-content: center;
}

.upload-file-text span {
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input__file {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.input__file-button-text {
  line-height: 1;
  margin-top: 1px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input__file-button {
  width: 100%;
  margin: 0 auto;
}

.input__file-button:active {
  width: 100%;
}

.w-checkbox-ageement {
  float: left;
  line-height: normal;
}

.success-message {
  display: block;
  margin: 0 auto;
}

.success-message a {
  color: #ffffff;
}

.w-select {
  padding-right: 20px;
}

.input-wrapper {
  position: relative;
}

.width-full {
  width: 372px;
}

@media (max-width: 767px) {
  .width-full {
    width: 100%;
  }
}

.select-delete {
  position: absolute;
  bottom: 9px;
  right: 24px;
  cursor: pointer;
}

.validation-error-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  font-size: 14px;
  vertical-align: top;
  top: 55px;
  left: 20px;
  position: absolute;
  opacity: 0;
  background: #000000;
  border-radius: 4px;
  color: #F2B8B5;
  font-weight: normal;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.13);

  img {
    padding: 0 5px 2px 0;
    position: relative;
  }
}

.validation-error-text:before {
  content: "";
  position: absolute;
  left: 1.4rem;
  top: -0.4rem;
  width: 0.9rem;
  height: 0.9rem;
  transform: rotate(45deg);
  background: #000000;
}

.input-wrapper input:hover + .validation-error-text,
.input-wrapper .input__file-button:hover + .validation-error-text,
.input-wrapper .file-uploaded:hover + .validation-error-text {
  opacity: 1;
  z-index: 20;
}

.input-wrapper textarea:hover + .validation-error-text {
  opacity: 1;
  z-index: 20;
  top: auto;
  bottom: -62px;
}


@media screen and (max-width: 479px) {
  .mob-ml-30 {
    margin-left: 30px;
  }
}

.file-uploaded {
  color: #00b8e3;
  word-break: break-all;
  max-width: 370px;
  height: auto;
  min-height: 44px;
  padding: 3px;
}

.has-error {
  color: #F2B8B5;
  border: 1px solid #F2B8B5;
}

.has-error::placeholder {
  color: #F2B8B5;
}

.comment-textarea {
  color: #FFFFFF;
}

.position-relative-vue {
  position: relative;
}

.error-button {
  position: absolute;
  right: 7px;
  top: 10px;
}

.error-button.file-uploader-error {
  right: -40px;
}

.error-button.checkbox-item {
  right: 0;
  left: -30px;
  top: 0;
}

.w-input {
  padding-right: 40px;
}

.has-error .upload-file-text {
  color: #F2B8B5;
}

.w-checkbox {
  position: relative;
  z-index: 2;
}

.close-icon-upload {
  margin-top: 5px;
}

@media (max-width: 480px) {
  .input__file-button-text {
    max-width: 200px;
  }

  .error-button.file-uploader-error {
    right: 22px;
  }

  .error-button.checkbox-item {
    right: 7px;
    left: auto;
    top: 10px;
  }

  .checkbox-content-box {
    padding-right: 27px;
  }

  .input-wrapper .file-uploaded + .validation-error-text,
  .input-wrapper .w-checkbox input + .validation-error-text,
  .input-wrapper .input__file-button + .validation-error-text {
    opacity: 1;
    z-index: 20;
  }
}
