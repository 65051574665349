
.w-checkbox-input {
    min-width: 18px;
}
.checkbox-input {
    opacity:0;
    position:absolute;
    z-index:-1;
}


.check-field {
    width: 100%;
    padding: 11.5px 0 11.5px 4.5px;
    margin: 0;
    cursor: pointer;
    background-color: transparent;
    &:hover {
        background-color: rgba(0, 4, 16, 0.34);
    }
}

.dropdown-list {
    padding-left: 0;
    width: 250px;
}

.clear-all-btn {
    margin: 0;
    display: block;
    background: transparent;
    color: #7a9fff;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    text-decoration: none;
}

@media screen and (max-width: 479px){
    .vacancies-filters {
        flex-direction: column;
        place-content: center;
    }
}
